import { getClassNames } from './NewLesson.classNames';
import MLModal from 'common/ml-components/MLModal';
import MLForm from 'common/ml-components/MLForm';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { AudioOnlyLessonDto, BulletPointLessonDto, CompleteTheSentenceLessonDto, FlashCardLessonDto, LessonTypeDto, QuizLessonDto, TextLessonDto, TrueFalseLessonDto } from 'api-client';
import LessonData from './components/LessonData/LessonData';
import Lesson from './components/Lesson/Lesson';
import MLVerticalDivider from 'common/ml-components/MLVerticalDivider';
import _ from "lodash";
import { addLesson, editAttachment } from 'store/slices/courses';
import { useParams } from 'react-router-dom';
import { IAttachment } from 'common/models/Learnings/attachment';

interface IBulletPointLesson {
  'introduction'?: string | null;
  'bulletPoints'?: Array<{ id: string; value: string }> | null;
  'isAudioOnly'?: boolean;
  'hasAudio'?: boolean;
  'id'?: string;
  'title'?: string | null;
  'type'?: LessonTypeDto;
  'isHide'?: boolean;
  'quote'?: string | null;
  'modified'?: string | null;
  'scratch'?: boolean | null;
}

interface IQuizLesson {
  'question'?: string | null;
  'options'?: Array<{ id: string; value: string }> | null;
  'correctAnswerId'?: number;
  'explanationCorrectAnswer'?: string;
  'isAudioOnly'?: boolean;
  'hasAudio'?: boolean;
  'id'?: string;
  'title'?: string | null;
  'type'?: LessonTypeDto;
  'isHide'?: boolean;
  'quote'?: string | null;
  'modified'?: string | null;
  'scratch'?: boolean | null;
}

interface ICompleteTheSentenceLesson {
  'options'?: Array<{ id: string; value: string }> | null;
  'correctWordId'?: number;
  'isAudioOnly'?: boolean;
  'hasAudio'?: boolean;
  'id'?: string;
  'title'?: string | null;
  'type'?: LessonTypeDto;
  'isHide'?: boolean;
  'quote'?: string | null;
  'modified'?: string | null;
  'scratch'?: boolean | null;
  sentence?: string;
}

export interface INewLesson {
  attachment: IAttachment | null | undefined;
  lesson: TextLessonDto | IBulletPointLesson | IQuizLesson | FlashCardLessonDto | AudioOnlyLessonDto | CompleteTheSentenceLessonDto | TrueFalseLessonDto | undefined;
}

interface INewLessonProps {
  show: boolean;
  onDismiss: () => void;
}

const NewLesson: React.FC<INewLessonProps> = ({ show, onDismiss }) => {
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const dispatch = useAppDispatch();
  const { lang } = useParams();

  const defaultValues: INewLesson = {
    attachment: null,
    lesson: {
      title: '',
      body: '',
      answer: '',
      bulletPoints: [],
      correctAnswerId: '0',
      explanation: '',
      explanationCorrectAnswer: '',
      hasAudio: true,
      introduction: '',
      showAudioPlayer: false,
      audioSource: '',
      options: [],
      question: '',
      scratch: true,
      quote: '',
      type: LessonTypeDto.Text,
    } as TextLessonDto | IBulletPointLesson | IQuizLesson | FlashCardLessonDto | AudioOnlyLessonDto | CompleteTheSentenceLessonDto | TrueFalseLessonDto | undefined,
  };

  const compareData = (values: INewLesson) => {
    if (values.lesson?.type === LessonTypeDto.CompleteTheSentence) {
      if ((values.lesson as CompleteTheSentenceLessonDto).correctWordId === undefined) return false;
    }
    if (!_.isEqual(values.lesson, defaultValues.lesson)) return true;
    if (!_.isEqual(values.attachment, defaultValues.attachment)) return true;
    return false;
  };

  const onSubmit = async (data: INewLesson) => {
    if (!data.lesson) return;

    try {
      let lessonId: string | undefined = undefined;

      if (!_.isEqual(data.lesson, defaultValues.lesson)) {
        if (data.lesson.type === LessonTypeDto.BulletPoints) {
          const bulletPoints = (data.lesson as IBulletPointLesson).bulletPoints?.map(point => point.value);
          const bulletPointsLesson = data.lesson as BulletPointLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...bulletPointsLesson,
            bulletPoints,
          } as BulletPointLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }

        if (data.lesson.type === LessonTypeDto.Quiz) {
          const options = (data.lesson as IQuizLesson).options?.map(opt => ({ id: Number(opt.id), response: opt.value}));
          const quizLesson = data.lesson as QuizLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...quizLesson,
            options,
          } as QuizLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }

        if (data.lesson.type === LessonTypeDto.Text) {
          const textLesson = data.lesson as TextLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...textLesson,
          } as TextLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }

        if (data.lesson.type === LessonTypeDto.FlashCard) {
          const flashCardLesson = data.lesson as FlashCardLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...flashCardLesson,
          } as FlashCardLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }

        if (data.lesson.type === LessonTypeDto.AudioOnly) {
          const audioOnlyLesson = data.lesson as AudioOnlyLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...audioOnlyLesson,
          } as AudioOnlyLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }

        if (data.lesson.type === LessonTypeDto.CompleteTheSentence) {
          const options = (data.lesson as ICompleteTheSentenceLesson).options?.map(opt => ({ id: Number(opt.id), response: opt.value}));
          const completeTheSentenceLesson = data.lesson as CompleteTheSentenceLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...completeTheSentenceLesson,
            options,
          } as CompleteTheSentenceLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }

        if (data.lesson.type === LessonTypeDto.TrueFalse) {
          const trueFalseLesson = data.lesson as TrueFalseLessonDto;
          const newLessons = await dispatch(addLesson({ lesson: {
            ...trueFalseLesson,
          } as TrueFalseLessonDto, lang: lang || documentLanguage || '', })).unwrap();

          lessonId = newLessons[0].id;
        }
      }

      if (!_.isEqual(data.attachment, defaultValues.attachment)) {
        if (data.attachment?.contentType)
          await dispatch(editAttachment({ lessonId: lessonId || '', lang: lang || documentLanguage || '', attachment: data.attachment })).unwrap();     
      }

      onDismiss();
    } catch (err) {
      console.error(err);
    }
  };

  const classes = getClassNames();

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width='110rem'
    >
      <MLForm
        title='New lesson'
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        compareData={compareData}
        noActionButtons
        noPaddingRight
      >
        <div className={classes.newLessonContainer}>
          <LessonData />
          <MLVerticalDivider />
          <Lesson
            onDismiss={onDismiss}
            compareData={compareData}
          />
        </div>
      </MLForm>
    </MLModal>
  );
};

export default NewLesson;