import { getClassNames } from "./lessons.classNames";
import { LessonDto } from "../../../../../api-client";
import Lesson from "./Lesson/lesson";
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../store/store';
import useAudio from './hooks/useAudio';
import { ApiStatus } from "common/models/store/api-status";
import Shimmers from "./components/Shimmers/Shimmers";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragStartEvent,
  DragOverlay,
  MeasuringStrategy,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useState } from "react";
import { changeLessonOrder, setLessonsOrder } from "store/slices/courses";
import { useParams } from "react-router-dom";

interface ILessonsProps {
  lessons: LessonDto[];
  onEditLessonClick: () => void;
  onDeleteClick: () => void;
}

const Lessons = ({ lessons, onEditLessonClick, onDeleteClick }: ILessonsProps) => {
  const apiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  // const lessons = useSelector((state: RootState) => state.courses.course?.lessons?.filter(lesson => lesson.type !== LessonTypeDto.FinalQuiz));
  const dispatch = useAppDispatch();
  const [activeId, setActiveId] = useState<string | null>(null);
  const { lang } = useParams();

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 200,
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    if (active) setActiveId(active.id.toString());
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    if (!lessons) return;

    const { active, over } = event;
      if (active.id !== over?.id) {
        const oldIndex = lessons.findIndex((item) => item.id === active.id);
        const newIndex = lessons.findIndex((item) => item.id === over?.id);
      try {  
        dispatch(setLessonsOrder(arrayMove(lessons, oldIndex, newIndex)));
        await dispatch(changeLessonOrder({ lang })).unwrap();
      } catch (err) {
        console.error(err);
        dispatch(setLessonsOrder(lessons));
      }
    }
  };

  const { onPlayAudioLessonId, setAudioLessonId } = useAudio();

  const classes = getClassNames();

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        },
      }}
    >
      <SortableContext 
        items={lessons?.map(item => item.id || '') || []}
      >
        <div className={classes.lessons}>
          {lessons?.map((lesson: LessonDto, index: number) => (
            <Lesson
              key={lesson.id}
              index={index}
              lesson={lesson}
              onEditLessonClick={onEditLessonClick}
              onDeleteClick={onDeleteClick}
              onPlayAudioLessonId={onPlayAudioLessonId}
              setAudioLessonId={setAudioLessonId}
              disabled={apiStatus.status === ApiStatus.SAVING}
            />
          ))}
          {apiStatus.status === ApiStatus.LOADING && <Shimmers />}
        </div>
      </SortableContext>
      <DragOverlay>
        {activeId ? (
          <Lesson
            key={activeId}
            index={lessons?.findIndex(lesson => lesson.id === activeId) || 0}
            lesson={lessons?.find(lesson => lesson.id === activeId) || {}}
            onEditLessonClick={onEditLessonClick}
            onDeleteClick={onDeleteClick}
            onPlayAudioLessonId={onPlayAudioLessonId}
            setAudioLessonId={setAudioLessonId}
            disabled={apiStatus.status === ApiStatus.SAVING}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
}

export default Lessons;