import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getTheme } from "@fluentui/react";
import Delete from "assets/ml-icons/Buttons/Delete/delete";
import Reorder from "assets/ml-icons/Controls/Reorder/reorder";
import DefaultButton from "common/ml-components/buttons/MLDefaultButton";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLToggle from "common/ml-components/inputs/MLToggle";
import { getClassNames } from "./MLItem.classNames";

interface IMLItemProps {
  item: { id: string | number; value: string; secondaryValue?: string; active?: boolean; ref: React.RefObject<HTMLDivElement> } | undefined;
  disabled?: boolean;
  deleteDisabled?: boolean;
  alternativeIcon?: JSX.Element;
  onAlternativeClick?: () => void;
  draggable?: boolean;
  withToggle?: boolean;
  nonEditable?: boolean;
  selected?: boolean;
  onClick?: () => void;
  onDelete: (id: string | number) => void;
  onToggle?: (id: string | number, checked: boolean) => void;
  onChange: (id: string | number, newValue: string) => void;
  onSecondaryChange: (id: string | number, newSecondaryValue: string) => void;
  prefix?: string;
  suffix?: string;
  secondarySuffix?: string;
}

const MLItem: React.FC<IMLItemProps> = ({
  item,
  disabled,
  deleteDisabled,
  alternativeIcon,
  onAlternativeClick,
  draggable,
  withToggle,
  nonEditable,
  selected,
  onClick,
  onDelete,
  onToggle,
  onChange,
  onSecondaryChange,
  prefix,
  suffix,
  secondarySuffix
}) => {
  const animateLayoutChanges = (args: any) => {
    const {isSorting, wasDragging } = args;
  
    if (isSorting || wasDragging ) {
      return defaultAnimateLayoutChanges(args);
    }
  
    return true;
  };

  const itemId = item?.id;

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ animateLayoutChanges, id: (itemId !== undefined && itemId !== null) ? itemId.toString() : '' });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    zIndex: isDragging ? "1" : "auto",
    opacity: isDragging ? 0.2 : 1,
  };

  const getButtonIcon = () => {
    if (!deleteDisabled) {
      return <Delete disabled={disabled || deleteDisabled} colored />;
    }
    if (deleteDisabled && alternativeIcon) {
      return alternativeIcon;
    }
  }

  const classes = getClassNames(!!disabled);

  return (  
    <div
      ref={setNodeRef}
      style={style}
      className={classes.itemContainer}
    >
      {draggable && (
        <div
          {...attributes}
          {...listeners}
        >
          <Reorder
            disabled={disabled}
          />
        </div>
      )}
      {withToggle && (
        <MLToggle
          name={`active-${item?.id}`}
          checked={item?.active}
          onChange={(e, checked) => withToggle && onToggle && onToggle(item?.id || '', !!checked)}
          uncontrolled
        />
      )}
      <MLTextField
        name={`item-${item?.id}`}
        value={item?.value}
        onChange={(e, newValue) => onChange(item?.id !== undefined ? item?.id : '', newValue || '')}
        multiline
        autoAdjustHeight
        resizable={false}
        disabled={disabled}
        uncontrolled
        readOnly={nonEditable || (alternativeIcon && deleteDisabled)}
        backgroundColor={selected ? getTheme().palette.themeLighter : undefined}
        onClick={onClick ? onClick : undefined}
        prefix={prefix}
        suffix={suffix}
      />
      {item?.secondaryValue !== undefined && (
        <MLTextField
          name={`secondaryItem-${item?.id}`}
          value={item?.secondaryValue}
          onChange={(e, newValue) => onSecondaryChange(item?.id || '', newValue || '')}
          multiline
          autoAdjustHeight
          resizable={false}
          disabled={disabled}
          uncontrolled
          readOnly={nonEditable}
          backgroundColor={selected ? getTheme().palette.themeLighter : undefined}
          onClick={onClick ? onClick : undefined}
          suffix={secondarySuffix}
          width={'35%'}
        />
      )}
      <DefaultButton       
        onClick={() => 
          deleteDisabled && onAlternativeClick ?
          onAlternativeClick() :
          onDelete(item?.id === undefined || item?.id === null ? '' : item?.id)}
        colored
        // icon={<Delete disabled={disabled || deleteDisabled} colored />}
        icon={getButtonIcon()}
        disabled={alternativeIcon ? false : disabled || deleteDisabled}
      />
  </div>
  );
};

export default MLItem;