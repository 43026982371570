import { BORDER_RADIUS } from "utils/constants/styles";
import { getClassNames } from "./TrueFalseLesson.classNames";
import { getTheme } from "@fluentui/react";

interface ITrueFalseLessonProps {
  sentence: string;
  isTrue: boolean;
  explanation: string;
}

const TrueFalseLesson = (props: ITrueFalseLessonProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.flashCardLessonContainer}>
      <div className={classes.flashCardContainer}>
        <p className={classes.question}>{props.sentence}</p>
        <div style={{ display: 'flex', justifyContent: 'center', gap: '5rem' }}>
        <div
          style={{
            flex: '0 0 10rem',
            height: '5rem',
            borderRadius: BORDER_RADIUS,
            background: props.isTrue ? getTheme().palette.themeLight : getTheme().palette.neutralLighter,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          True
        </div>
        <div
          style={{
            flex: '0 0 10rem',
            height: '5rem',
            borderRadius: BORDER_RADIUS,
            background: !props.isTrue ? getTheme().palette.themeLight : getTheme().palette.neutralLighter,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '1.5rem',
            fontWeight: 'bold',
          }}
        >
          False
        </div>
      </div>
        <p className={classes.explanation}>{props.explanation}</p>
      </div>
    </div>
  );
}

export default TrueFalseLesson;