// import react
import { useEffect, useRef } from 'react';

// import styles
import { getClassNames } from "./CourseOptions.classNames";

// import components
import MLTextField from 'common/ml-components/inputs/MLTextField';
import Dates from './components/Dates/Dates';
import MLCheckbox from 'common/ml-components/inputs/MLCheckbox';
import MLHorizontalDivider from 'common/ml-components/MLHorizontalDivider';
import Cover from './components/Cover/Cover';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';

interface ICourseOptionsProps {
  updateHeight: (height: number) => void;
}

const CourseOptions: React.FC<ICourseOptionsProps> = ({ updateHeight }) => {
  const generatedByFileType = useSelector((state: RootState) => state.courses.course?.generatedByFileType);
  const featureConversationalSearch = useSelector((state: RootState) => state.settings.settings?.planInfo?.featureConversationalSearch);
  const isSearchAvailable = useSelector((state: RootState) => state.settings.settings?.planInfo?.isSearchAvailable);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      updateHeight(ref.current.scrollHeight);
    }
  }, [updateHeight]);

  const classes = getClassNames();

  return (
    <div ref={ref} className={classes.courseOptionsContainer}>
      <div
        style={{ display: 'flex', flexDirection: 'column', gap: '2rem' }}>
        <MLTextField
          name='courseOptions.title'
          label='Title'
        />
        <MLTextField
          name='courseOptions.description'
          label='Description'
          formRequired
          multiline
          rows={5}
          autoAdjustHeight={false}
          resizable={false}
        />
        <Cover />
      </div>

      <MLHorizontalDivider />

      <Dates />

      <MLHorizontalDivider />

      {generatedByFileType !== 'None' && featureConversationalSearch && isSearchAvailable && (
        <MLCheckbox
        name='courseOptions.isSearchEnabled'
        label='Enable search'
      />
      )}
    </div>
  );
};

export default CourseOptions;