import { BulletPointLessonDto, LessonDto, LessonTypeDto, QuizLessonDto, TextLessonDto, FlashCardLessonDto, AudioOnlyLessonDto, CompleteTheSentenceLessonDto, TrueFalseLessonDto } from "../../../../../../../api-client";
import TextLesson from "./TextLesson/text-lesson";
import BulletPointsLesson from "./BulletPointsLesson/bullet-points-lesson";
import QuizLesson from "./QuizLesson/quiz-lesson";
import FlashCardLesson from "./FlashCardLesson/FlashCardLesson";
import AudioOnlyLesson from "./AudioOnlyLesson/AudioOnlyLesson";
import CompleteTheSentenceLesson from "./CompleteTheSentenceLesson/CompleteTheSentenceLesson";
import TrueFalseLesson from "./TrueFalseLesson/TrueFalseLesson";

interface IBodyProps {
  lesson: LessonDto;
  onPlayAudioLessonId?: string;
  setAudioLessonId?: (id: string) => void;
}

const Body = (props: IBodyProps) => {
  switch (props.lesson.type) {
    case LessonTypeDto.Text: return (
      <TextLesson text={(props.lesson as TextLessonDto).body || ''} />
    );
    case LessonTypeDto.BulletPoints: return (
      <BulletPointsLesson introduction={(props.lesson as BulletPointLessonDto).introduction || ''} bulletPoints={(props.lesson as BulletPointLessonDto).bulletPoints || []} />
    )
    case LessonTypeDto.Quiz: return (
      <QuizLesson question={(props.lesson as QuizLessonDto).question || ''} options={(props.lesson as QuizLessonDto).options || []} correctAnswerId={(props.lesson as QuizLessonDto).correctAnswerId} />
    )
    case LessonTypeDto.CompleteTheSentence: return (
      <CompleteTheSentenceLesson sentence={(props.lesson as CompleteTheSentenceLessonDto).sentence || ''} options={(props.lesson as CompleteTheSentenceLessonDto).options || []} correctWordId={(props.lesson as CompleteTheSentenceLessonDto).correctWordId} />
    )
    case LessonTypeDto.AudioOnly:{
      if (props.onPlayAudioLessonId !== undefined && props.setAudioLessonId) {
        return (
          // <TextLesson text={(props.lesson as AudioOnlyLessonDto).audioSource || ''} />
          <AudioOnlyLesson
            lesson={props.lesson as AudioOnlyLessonDto}
            onPlayAudioLessonId={props.onPlayAudioLessonId}
            setAudioLessonId={props.setAudioLessonId}
          />
        )  
      } else return null;
    }
    case LessonTypeDto.FlashCard: return (
      <FlashCardLesson
        question={(props.lesson as FlashCardLessonDto).question || ''}
        answer={(props.lesson as FlashCardLessonDto).answer || ''}
        explanation={(props.lesson as FlashCardLessonDto).explanation || ''}
      />
    )
    case LessonTypeDto.TrueFalse: return (
      <TrueFalseLesson
        sentence={(props.lesson as TrueFalseLessonDto).sentence || ''}
        isTrue={(props.lesson as TrueFalseLessonDto).isTrue || false}
        explanation={(props.lesson as FlashCardLessonDto).explanation || ''}
      />
    )
    default: return <div>NO TYPE</div>
   }
};

export default Body;