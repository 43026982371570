import { getTheme } from "@fluentui/react";
import { IIconProps } from "common/models/Icons/icon-props";

const Save = ({ colored }: IIconProps) => {
  const theme = getTheme();

  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 2C0 0.895431 0.89543 0 2 0H10.3787C10.9091 0 11.4178 0.210714 11.7929 0.585786L13.4142 2.20711C13.7893 2.58218 14 3.09089 14 3.62132V12C14 13.1046 13.1046 14 12 14H2C0.895431 14 0 13.1046 0 12V2ZM2 1C1.44772 1 1 1.44772 1 2V12C1 12.5523 1.44772 13 2 13L2 8.5C2 7.67157 2.67157 7 3.5 7H10.5C11.3284 7 12 7.67157 12 8.5V13C12.5523 13 13 12.5523 13 12V3.62132C13 3.3561 12.8946 3.10175 12.7071 2.91421L11.0858 1.29289C10.8983 1.10536 10.6439 1 10.3787 1L10 1V3.5C10 4.32843 9.32843 5 8.5 5L4.5 5C3.67157 5 3 4.32843 3 3.5L3 1H2ZM4 1L4 3.5C4 3.77614 4.22386 4 4.5 4L8.5 4C8.77614 4 9 3.77614 9 3.5V1L4 1ZM11 13V8.5C11 8.22386 10.7761 8 10.5 8H3.5C3.22386 8 3 8.22386 3 8.5V13H11Z" fill={colored ? theme.palette.themePrimary : theme.palette.black}/>
    </svg>
  )
};

export default Save;