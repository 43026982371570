import { useFormContext } from "react-hook-form";
import { INewLesson } from "../../NewLesson";
import { LessonDto, LessonTypeDto } from "api-client";
import TextLesson from "./components/TextLesson/TextLesson";
import BulletPointsLesson from "./components/BulletPointsLesson/BulletPointsLesson";
import QuizLesson from "./components/QuizLesson/QuizLesson";
import FlashCardLesson from "./components/FlashCardLesson/FlashCardLesson";
import ActionButtons from "common/ml-components/MLForm/components/ActionButtons/ActionButtons";
import { getClassNames } from "./Lesson.classNames";
import { useEffect, useState } from "react";
import MLDialog from "common/ml-components/MLDialog";
import { lessonTypesOptions } from 'utils/constants/lesson-types';
import MLDropdown, { DropdownStyles } from "common/ml-components/inputs/MLDropdown";
import AudioLesson from "./components/AudioLesson/AudioLesson";
import MLCheckbox from "common/ml-components/inputs/MLCheckbox";
import CompleteTheSentenceLesson from "./components/CompleteTheSentenceLesson/CompleteTheSentenceLesson";
import TrueFalseLesson from "./components/TrueFalseLesson/TrueFalseLesson";

interface ILessonProps {
  onDismiss: () => void;
  compareData?: (data: INewLesson) => boolean;
}

const Lesson: React.FC<ILessonProps> = ({ onDismiss, compareData }) => {
  const [isDataChanged, setIsDataChanged] = useState(!compareData);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const { getValues, formState: { dirtyFields }, watch } = useFormContext();
  const newLesson = getValues('lesson') as LessonDto;

  const data = watch();

  const onCancel = () => {
    if (!compareData) return onDismiss();
    if (isDataChanged) setShowDialog(true);
    else onDismiss();
  };

  useEffect(() => {
    // Check if any of the form values have changed
    if (!compareData) return;

    const hasChanged = Object.keys(dirtyFields).length > 0;
    if (hasChanged) {
      setIsDataChanged(compareData(data as INewLesson));
    } else setIsDataChanged(false);
  }, [dirtyFields, compareData, data]);
  
  const classes = getClassNames();

  return (
    <div className={classes.lessonContainer}>
      <div className={classes.lesson}>
        <div className={classes.lessonTypeContainer}>
          <MLDropdown
            name='lesson.type'
            label='Lesson type'
            options={lessonTypesOptions}
            dropdownStyle={DropdownStyles.LIGHT}
            dropdownWidth={200}
          />
          {(newLesson.type === LessonTypeDto.Text ||
            newLesson.type === LessonTypeDto.BulletPoints) && (
              <MLCheckbox
                name='lesson.showAudioPlayer'
                label='Show audio'
              />
            )}
          </div>
          <div className={classes.lessonContent}>
            {newLesson.type === LessonTypeDto.Text && <TextLesson />}
            {newLesson.type === LessonTypeDto.BulletPoints && <BulletPointsLesson />}
            {newLesson.type === LessonTypeDto.Quiz && <QuizLesson />}
            {newLesson.type === LessonTypeDto.FlashCard && <FlashCardLesson />}
            {newLesson.type === LessonTypeDto.AudioOnly && <AudioLesson />}
            {newLesson.type === LessonTypeDto.CompleteTheSentence && <CompleteTheSentenceLesson />}
            {newLesson.type === LessonTypeDto.TrueFalse && <TrueFalseLesson />}
          </div>
      </div>
      <ActionButtons
        onDismiss={onCancel}
        isSaveEnabled={isDataChanged}
      />
      <MLDialog
        show={showDialog}
        onConfirm={() => {
          setShowDialog(false);
          onDismiss();
        }}
        onCancel={() => setShowDialog(false)}
        title='Unsaved changes'
        text='You have unsaved changes. Are you sure you want to cancel and lose all changes?'
      />
    </div>
  );
};

export default Lesson;