import { IndexedResponseDto } from "../../../../../../../../api-client";
import { getClassNames } from "./CompleteTheSentenceLesson.classNames";

interface ICompleteTheSentenceLessonProps {
  sentence: string;
  options: IndexedResponseDto[];
  correctWordId: number | undefined;
}

const CompleteTheSentenceLesson = (props: ICompleteTheSentenceLessonProps) => {
  const classes = getClassNames();

  return (
    <div className={classes.quizLessonContainer}>
      <div className={classes.quizContainer}>
        <div className={classes.quizScrollContainer}>
          <p className={classes.question}>{props.sentence}</p>
          <br />
          <div className={classes.answerList}>
            {props.options.map((option: IndexedResponseDto, index: number) => (
              <div key={index} className={option.id === props.correctWordId ? classes.correctAnswer : classes.answer}>
                <p className={classes.response}>{option.response}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
};

export default CompleteTheSentenceLesson;