import { getClassNames } from './EditLesson.classNames';
import MLModal from 'common/ml-components/MLModal';
import MLForm from 'common/ml-components/MLForm';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from 'store/store';
import { AudioOnlyLessonDto, BulletPointLessonDto, CompleteTheSentenceLessonDto, FlashCardLessonDto, LessonTypeDto, QuizLessonDto, TextLessonDto, TrueFalseLessonDto } from 'api-client';
import LessonData from './components/LessonData/LessonData';
import Lesson from './components/Lesson/Lesson';
import MLVerticalDivider from 'common/ml-components/MLVerticalDivider';
import _ from "lodash";
import { changeLessonType, deleteAttachment, editAttachment, saveLesson } from 'store/slices/courses';
import { useNavigate, useParams } from 'react-router-dom';
import { IAttachment } from 'common/models/Learnings/attachment';
import { v4 } from 'uuid';
import { useContext, useMemo } from 'react';
import { LoadingContext } from 'contexts/loading-context';
import { timeout } from 'utils/helpers/helpers';
import { InfoContext } from 'contexts/info-context';

interface IBulletPointLesson {
  'introduction'?: string;
  'bulletPoints'?: Array<{ id: string; value: string }>;
  'isAudioOnly'?: boolean;
  'hasAudio'?: boolean;
  'id'?: string;
  'title'?: string;
  'type'?: LessonTypeDto;
  'isHide'?: boolean;
  'quote'?: string;
  'modified'?: string;
  'scratch'?: boolean;
}

interface IQuizLesson {
  'question'?: string | null;
  'options'?: Array<{ id: string; value: string }> | null;
  'correctAnswerId'?: number;
  'explanationCorrectAnswer'?: string;
  'isAudioOnly'?: boolean;
  'hasAudio'?: boolean;
  'id'?: string;
  'title'?: string | null;
  'type'?: LessonTypeDto;
  'isHide'?: boolean;
  'quote'?: string | null;
  'modified'?: string | null;
  'scratch'?: boolean | null;
}

interface ICompleteTheSentenceLesson {
  'options'?: Array<{ id: string; value: string }> | null;
  'correctWordId'?: number;
  'isAudioOnly'?: boolean;
  'hasAudio'?: boolean;
  'id'?: string;
  'title'?: string | null;
  'type'?: LessonTypeDto;
  'isHide'?: boolean;
  'quote'?: string | null;
  'modified'?: string | null;
  'scratch'?: boolean | null;
  sentence?: string;
}

export interface ILessonToEdit {
  attachment: IAttachment | undefined;
  lesson: TextLessonDto | IBulletPointLesson | QuizLessonDto | FlashCardLessonDto | AudioOnlyLessonDto | TrueFalseLessonDto | CompleteTheSentenceLessonDto | undefined;
}

interface IEditLessonProps {
  show: boolean;
  onDismiss: () => void;
}

const EditLesson: React.FC<IEditLessonProps> = ({ show, onDismiss }) => {
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const { info: { modifiedLessons, addModifiedLesson } } = useContext(InfoContext);
  const documentLanguage = useSelector((state: RootState) => state.courses.course?.documentLanguage);
  const lessonToEdit = useSelector((state: RootState) => state.courses.lessonToEdit);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { lang } = useParams();

  const lesson = useMemo(() => {
    if (!lessonToEdit) {
      return {
        title: '',
        body: '',
        quote: '',
        modified: '',
        options: [],
        bulletPoints: [],
        isHide: false,
        showAudioPlayer: true,
        type: LessonTypeDto.Text,
        scratch: false,
      }
    } else {
      // if (lessonToEdit.type === LessonTypeDto.Text) return lessonToEdit as TextLessonDto;
      if (lessonToEdit?.type === LessonTypeDto.BulletPoints) {
        return {
          ...lessonToEdit,
          bulletPoints: (lessonToEdit as BulletPointLessonDto).bulletPoints?.map(bullet => ({
            id: v4(),
            value: bullet,
          })),
        }
      }
      
      if (lessonToEdit?.type === LessonTypeDto.Quiz) {
        return {
          ...lessonToEdit,
          options: (lessonToEdit as QuizLessonDto).options?.map(option => ({
            id: option.id,
            value: option.response,
          }))
        }
      }

      if (lessonToEdit?.type === LessonTypeDto.CompleteTheSentence) {
        return {
          ...lessonToEdit,
          options: (lessonToEdit as CompleteTheSentenceLessonDto).options?.map(option => ({
            id: option.id,
            value: option.response,
          }))
        }
      }
      return lessonToEdit;
    }
  }, [lessonToEdit]);

  const defaultValues: ILessonToEdit = {
    attachment: undefined,
    lesson,
  };

  const compareData = (values: ILessonToEdit) => {
    if (!_.isEqual(values.lesson, defaultValues.lesson)) return true;
    if (values.attachment?.originalData !== values.attachment?.data) return true;
    return false;
  };

  const onSubmit = async (data: ILessonToEdit) => {
    if (!data.lesson) return;

    try {
      // check if the lesson type was changed
      if (defaultValues.lesson?.type !== data.lesson.type) {
        await dispatch(changeLessonType({ lessonId: data.lesson.id, type: data.lesson.type, lang: lang || documentLanguage || '' })).unwrap();
        if (!modifiedLessons.includes(data.lesson.id || '')) addModifiedLesson(data.lesson.id || '');
        onDismiss();
        startLoading('Redirecting to the learnings page...');
        await timeout(6000);
        // await dispatch(getCourses()).unwrap();
        stopLoading();
        navigate('/learnings');
        return;
      }

      const promiseArray = [];

      if (!_.isEqual(data.lesson, defaultValues.lesson)) {
        switch (data.lesson.type) {
          case LessonTypeDto.BulletPoints:
            promiseArray.push(() =>
              dispatch(
                saveLesson({
                  lesson: {
                    ...data.lesson,
                    bulletPoints: (data.lesson as IBulletPointLesson).bulletPoints?.map(point => point.value),
                  },
                  lang: lang || documentLanguage || '',
                })
              )
            );
            break;
        
          case LessonTypeDto.Quiz:
            promiseArray.push(() =>
              dispatch(
                saveLesson({
                  lesson: {
                    ...data.lesson,
                    options: (data.lesson as IQuizLesson).options?.map(opt => ({ id: Number(opt.id), response: opt.value })),
                  },
                  lang: lang || documentLanguage || '',
                })
              )
            );
            break;

            case LessonTypeDto.CompleteTheSentence:
              promiseArray.push(() =>
                dispatch(
                  saveLesson({
                    lesson: {
                      ...data.lesson,
                      options: (data.lesson as ICompleteTheSentenceLesson).options?.map(opt => ({ id: Number(opt.id), response: opt.value })),
                    },
                    lang: lang || documentLanguage || '',
                  })
                )
              );
              break;
        
          default:
            promiseArray.push(() =>
              dispatch(
                saveLesson({
                  lesson: data.lesson || {},
                  lang: lang || documentLanguage || '',
                })
              )
            );
            break;
        }
      }

      if (!modifiedLessons.includes(data.lesson.id || '')) addModifiedLesson(data.lesson.id || '');

      console.log('data.attachment', data.attachment);
      if (data.attachment?.originalData !== data.attachment?.data) {
        if (!!data.attachment?.data) {
          promiseArray.push(() => dispatch(editAttachment({ lessonId: data.lesson?.id || '', lang: lang || documentLanguage || '', attachment: data.attachment })));
        } else {
          promiseArray.push(() => dispatch(deleteAttachment({ lessonId: data.lesson?.id || '', lang: lang || documentLanguage || '' })));
        }
      }

      await Promise.all(promiseArray.map(dispatchFn => dispatchFn()));
      onDismiss();
    } catch (err) {
      console.error(err);
    }
  };

  const classes = getClassNames();

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width='110rem'
    >
      <MLForm
        title='Edit lesson'
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        compareData={compareData}
        noActionButtons
        noPaddingRight
      >
        <div className={classes.editLessonContainer}>
          <LessonData />
          <MLVerticalDivider />
          <Lesson
            onDismiss={onDismiss}
            compareData={compareData}
            originalLessonType={defaultValues?.lesson?.type}
          />
        </div>
      </MLForm>
    </MLModal>
  );
};

export default EditLesson;