// react
import { useEffect, PropsWithChildren, useCallback } from "react"

// microsoft teams
import * as MsTeams from "@microsoft/teams-js";
import { RootState, useAppDispatch } from "../../store/store";
import { setAccount } from "../../store/slices/account";
import { useSelector } from "react-redux";

export default function Teams({children}: PropsWithChildren) {
  const { token } = useSelector((state: RootState) => state.account);
  const dispatch = useAppDispatch();

  function parseJwt (token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const getAccount = useCallback(async () => {
    try {
      await MsTeams.app.initialize();
      const username = (await MsTeams.app.getContext()).user?.userPrincipalName;
      const token = await MsTeams.authentication.getAuthToken({});
      if (!username || !token) return;
      const roles = parseJwt(token).roles;
      dispatch(setAccount({username, token, graphToken: token, roles}));
    }
    catch (err) {
      console.error(err);
    }
  }, [dispatch]);

  useEffect(() => {
    getAccount()
  }, [getAccount]);

  return (
    <>{token ? children : null}</>
  )
}