import { TextField, ITextFieldProps, ITextFieldStyles, getTheme } from "@fluentui/react";
import { useFormContext, Controller } from "react-hook-form";
import { BORDER_RADIUS, TEXT_FONT_SIZE } from "utils/constants/styles";

interface IMLTextFieldProps extends ITextFieldProps {
  name: string;
  formRequired?: boolean;
  handleChange?: (newValue: string | undefined, onChange: (newValue: string | undefined) => void) => void;
  backgroundColor?: string;
  isPassingPercentage?: boolean;
  isNumeric?: boolean;
  uncontrolled?: boolean;
  uncontrolledError?: boolean;
 }

const MLTextField: React.FC<IMLTextFieldProps> = ({
  name,
  formRequired,
  handleChange,
  backgroundColor,
  isPassingPercentage,
  isNumeric,
  uncontrolled,
  uncontrolledError,
  ...props
}) => {
  const { control } = useFormContext();

  const theme = getTheme();
  const getTextFieldStyles = (error: boolean): Partial<ITextFieldStyles> => {
    return {
      root: {
        width: props.width ? props.width : '100%',
        '*': {
          userSelect: props.readOnly ? 'none !important' : 'unset',

          '::selection': {
            overflow: 'hidden',
            backgroundColor: theme.palette.themeLight,
            borderRadius: BORDER_RADIUS, 
          }
        },
      },

      wrapper: {
        minHeight: '3.2rem',
      },
  
      fieldGroup: {
        minHeight: '3.2rem',
        height: 'unset',
        borderRadius: props.suffix ? `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}` : props.prefix ? `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0` : BORDER_RADIUS,
        borderColor: 'transparent',
  
        selectors: {
          ':hover': {
            borderRadius: props.suffix ? `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}` : props.prefix ? `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0` : BORDER_RADIUS,
            borderColor: 'transparent',
          },
          ':after': {
            borderRadius: props.suffix ? `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}` : props.prefix ? `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0` : BORDER_RADIUS,
            borderColor: 'transparent',
            inset: '0',
          },
        }, 
      },
  
      field: {
        minHeight: '3.2rem',
        height: (!!props.multiline && !props.autoAdjustHeight) ? 'auto' : '3.2rem',
        overflow: 'hidden',
        fontSize: TEXT_FONT_SIZE,
        backgroundColor: backgroundColor ? backgroundColor : theme.palette.neutralLighterAlt,
        borderRadius: props.suffix ? `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}` : props.prefix ? `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0` : BORDER_RADIUS,
        color: 'black',
        cursor: props.readOnly ? (props.onClick ? 'pointer' : 'default') : 'auto',
        transition: 'background-color 300ms',
      },
  
      subComponentStyles: {
        label: {
          root: {
            fontSize: '1.2rem',
            color: error ? theme.palette.red : theme.palette.neutralSecondaryAlt,
            fontWeight: 600,
            padding: 0,
            marginBottom: '0.5rem',
          }
        },
      },

      suffix: {
        minHeight: '3.2rem',
        borderRadius: `0 ${BORDER_RADIUS} ${BORDER_RADIUS} 0`,
      },

      prefix: {
        minHeight: '3.2rem',
        borderRadius: `${BORDER_RADIUS} 0 0 ${BORDER_RADIUS}`,
      },
    };
  };

  const getValue = (value?: string | number | null) => {
    if (value === null || value === undefined) {
      return '';
    }

    if (isNumeric) {
      return value === 0 ? '' : value.toString();
    }

    return value.toString()
  }

  return (
    <>
      {uncontrolled ? (
        <TextField
          {...props}
          styles={() => getTextFieldStyles(!!uncontrolledError)}
        />
      ) : (
        <Controller
          name={name}
          control={control}
          rules={{ required: formRequired ? 'true' : undefined }}
          render={({ field, fieldState: { error } }) => (
            <TextField
              {...field}
              {...props}
              onSelect={props.onSelect ? props.onSelect : undefined}
              // value={isNumeric ? field.value === 0 ? '' : field.value.toString() : field.value}
              value={getValue(field.value)}
              onChange={(_, newValue?: string) => {
                if (handleChange ) {
                  handleChange (newValue, field.onChange);
                  return;
                }

                if (isNumeric) {
                  // Prevent non-numeric characters except for dot, minus, etc.
                  const numericPattern = /^-?\d*\.?\d*$/;
                  
                  // Only allow valid numeric values
                  if (numericPattern.test(newValue || '')) {
                    newValue === '0' || newValue === '' ? field.onChange(null) : field.onChange(parseInt(newValue || '0'));
                  }

                  return;
                }

                if (isPassingPercentage) {
                  if (!isNaN(Number(newValue))) {
                    const numericValue = Number(newValue);
              
                    // Ensure the number is between 0 and 100
                    if (numericValue >= 0 && numericValue <= 100) {
                      field.onChange(newValue);
                    } else if (newValue === '') {
                      // Allow clearing the field
                      field.onChange('0');
                    }
                  }

                } else field.onChange(newValue);
              }}
              styles={() => getTextFieldStyles(!!error)}
            />
          )}
      />)}
    </>
  )
}

export default MLTextField;