import { mergeStyleSets, getTheme } from "@fluentui/react";
import { BORDER_RADIUS } from "utils/constants/styles";
import { TEXT_FONT_SIZE } from "utils/constants/styles";

interface IQuizClassNames {
  quizLessonContainer: string;
  quizContainer: string;
  quizScrollContainer: string;
  question: string;
  answerList: string;
  answer: string;
  response: string;
  correctAnswer: string;
  letterContainer: string;
  letter: string;
  dropdownAnswerContainer: string;
  hr: string;
}

export const getClassNames = (): IQuizClassNames => {
  const theme = getTheme();

  return mergeStyleSets({
    quizLessonContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
      margin: '0px 20px',
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },

    quizContainer: {
      overflow: 'hidden',
      flex: 1,
      paddingBottom: 10,
    },

    quizScrollContainer: {
      overflow: 'auto',
      width: '100%',
      height: '100%',
      paddingRight: 10,
    },

    question: {
      fontSize: TEXT_FONT_SIZE,
    },

    answerList: {
      marginTop: '2rem',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',  
      gap: '2rem',
    },

    answer: {
      padding: 10,
      background: theme.palette.themeLighterAlt,
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    correctAnswer: {
      padding: 10,
      background: theme.palette.themeLight,
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },

    response: {
      fontSize: TEXT_FONT_SIZE,
    },

    letterContainer: {
      flex: '0 0 24px',
      display: 'flex',
      borderRight: `2px solid ${theme.palette.themeTertiary}`
    },

    letter: {
      textTransform: 'uppercase',
      fontSize: '1.6rem',
    },

    dropdownAnswerContainer: {
      flex: '0 0 40px',
      display: 'flex',
      alignItems: 'center',
    },

    hr: {
      borderTop: `1px solid ${theme.palette.neutralLight}`
    }
  });
}