import { Configuration, RedirectRequest, SilentRequest } from '@azure/msal-browser';
import { ApplicationSettings } from '../settings/application';

export const msalConfig: Configuration = {
  auth: {
      authority: ApplicationSettings.APPLICATION_AUTHORITY,
      clientId: ApplicationSettings.APPLICATION_CLIENTID,
      navigateToLoginRequestUrl: true,
      redirectUri: window.location.origin + '/login',
  },
  // cache: {
  //   cacheLocation: 'localStorage',
  //   storeAuthStateInCookie: true,
  // }
};

export const authenticationRequest: RedirectRequest | SilentRequest = {
  scopes: [
      ApplicationSettings.IAI_APP_API_SCOPE
  ]
};

export const graphRequest: RedirectRequest | SilentRequest = {
  scopes: [
      'https://graph.microsoft.com/.default'
  ]
};