// import react
import { useMemo, FormEvent } from 'react';

// import styles
import { getClassNames } from "./Actions.classNames";
import ContextualMenu from 'common/ml-components/MLContextualMenu';

// import models
import { CourseStatusDto, FileType, FileTypeDto, LessonTypeDto, SupportedLanguageDto } from '../../../../../../api-client';
import { IDropdownOption,
  getTheme,
  IContextualMenuProps,
  IContextualMenuItemProps,
  ContextualMenuItemType,
  DirectionalHint } from '@fluentui/react';
import { IconNames } from 'common/models/Icons/icon-names';

// import utils
import { getLanguageFromCode, sortString } from 'utils/helpers/helpers';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store/store';
import { Icons } from 'utils/constants/icons';
import MLDropdown, { DropdownStyles } from 'common/ml-components/inputs/MLDropdown';

interface IActionsProps {
  onConfigureClick: () => void;
  onFinalQuizClick: () => void;
  onScheduleClick: () => void;
  onCourseDeleteClick: () => void;
  onExportScormClick: () => void;
  onNewLessonClick: () => void;
  onGenerateLessonClick: () => void;
  onPublishOrUnpublishClick: () => void;
  onCloneCourseClick: () => void;
  onDownloadDocumentClick: () => Promise<void>;
  onGenerateFinalQuizClick: () => void;
}

const Actions: React.FC<IActionsProps> = ({
  onConfigureClick,
  onCourseDeleteClick,
  onNewLessonClick,
  onGenerateLessonClick,
  onFinalQuizClick,
  onScheduleClick,
  onExportScormClick,
  onPublishOrUnpublishClick,
  onCloneCourseClick,
  onDownloadDocumentClick,
  onGenerateFinalQuizClick
}) => {
  const course = useSelector((state: RootState) => state.courses.course);
  const featureScormExport = useSelector((state: RootState) => state.settings.settings?.planInfo?.featureScormExport);

  const { id, lang } = useParams();
  const navigate =  useNavigate();

  const classes = getClassNames();

  const onLanguageChange = (e: FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
    if(!option || !id) return;
    if(option.key === lang) return;
    if (!lang && option.key === course?.documentLanguage) return;

    let path = `/learnings/${id}/${option.key}`;
    navigate(path, {replace: true});
  };

  const languages = useMemo((): IDropdownOption[] => {
    if (!course?.supportedLanguages) return [];

    return course.supportedLanguages?.map((language: SupportedLanguageDto): IDropdownOption => {
      if (!language.language) return { key: '', text: '' };

      return {
      key: language.language?.toString() || '',
      text: getLanguageFromCode(language.language),
      };
    }).sort((a, b) => sortString(a.text, b.text));
  }, [course?.supportedLanguages]);

  const hasFinalQuiz = course?.lessons?.some(el => el.type === LessonTypeDto.FinalQuiz);

  const publishedUrl = lang ? course?.supportedLanguages?.find(language => language.language === lang)?.publishedUrl : course?.supportedLanguages?.find(language => language.language === course.documentLanguage)?.publishedUrl;

  const onPublishedUrlClick = () => {
    publishedUrl && window.open(publishedUrl, '_blank');
  };

  const theme = getTheme();
  const itemProps = {
    styles: {
      label: {
        fontWeight: theme.fonts.xLarge.fontWeight,
      },
    }
  };

  const dividerProps: Partial<IContextualMenuItemProps> = {
    styles: {
      divider: {
        backgroundColor: theme.palette.neutralQuaternaryAlt,
        width: '90%',
        margin: '0 auto',
      }
    }
  }

  const contextualMenuProps: IContextualMenuProps = {
    directionalHint: DirectionalHint.topAutoEdge,
    items: [
      { 
        key: 'newLesson',
        text: 'New lesson',
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.ADD_LESSON];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
        subMenuProps: {
          items: [
            ...course?.generatedByFileType !== FileTypeDto.None ? [{
              key: 'fromDocument',
              text: 'From document',
              onClick: onGenerateLessonClick,
            }] : [],
            {
              key: 'fromScratch',
              text: 'From scratch',
              onClick: onNewLessonClick,
            },
          ]
        }
      },
      {
        key: 'configure',
        text: 'Configure',
        onClick: onConfigureClick,
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.CONFIGURE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        }
      },
      { 
        key: 'schedule',
        text: 'Schedule',
        onClick: onScheduleClick,
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.SCHEDULE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
      },
      { 
        key: 'publish',
        text: course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running ? 'Unpublish' : 'Publish',
        onClick: onPublishOrUnpublishClick,
        disabled: course?.status === CourseStatusDto.Running,
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = 
            course?.status === CourseStatusDto.Published || 
            course?.status === CourseStatusDto.Running ? Icons[IconNames.UNPUBLISH] : Icons[IconNames.PUBLISH];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon color={course?.status === CourseStatusDto.Running ? theme.palette.neutralTertiary : '#000'} />
            </div>
          )
        },
      },
      {
        key: 'divider1',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      { 
        key: 'finalQuiz',
        text: hasFinalQuiz ? 'Final quiz' : 'Generate final quiz',
        onClick: hasFinalQuiz ? undefined : onGenerateFinalQuizClick,
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.FINAL_QUIZ];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
        ...(hasFinalQuiz && {
          subMenuProps: {
            items: [
              {
                key: 'viewFinalQuiz',
                text: 'View final quiz',
                onClick: onFinalQuizClick,
              },
              {
                key: 'regenarateFinalQuiz',
                text: 'Regenerate final quiz',
                onClick: onGenerateFinalQuizClick,
              },
            ]
          }
        })
      },
      {
        key: 'divider2',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      { 
        key: 'clone',
        text: 'Clone',
        onClick: onCloneCourseClick,
        itemProps,
        iconProps: {},
        onRenderIcon: (props: IContextualMenuItemProps | undefined) => {
          const Icon = Icons[IconNames.CLONE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon />
            </div>
          )
        },
      },
      ...(featureScormExport ? [{
        key: 'scorm',
        text: 'Export in SCORM',
        onClick: onExportScormClick,
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.SCORM];
          return (
            <div className={classes.menuItemIconContainer} style={{transform: 'scale(1.15)'}}>
              <Icon />
            </div>
          )
        },
      }] : []),
      {
        key: 'divider3',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      ...(publishedUrl ? [{
        key: 'publishedUrl',
        text: 'Go to course',
        onClick: onPublishedUrlClick,
        onRenderIcon: () => {
          const Icon = Icons[IconNames.SHAREPOINT];
          return (
            <div className={classes.menuItemIconContainer}style={{marginLeft: 4}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: -2 }}><Icon /></div>
            </div>
          )
        },
      }] : []),
      ...(course?.generatedByFileType !== FileType.None ? [{
        key: 'downloadDocument',
        text: 'Download document',
        onClick: onDownloadDocumentClick,
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.DOWNLOAD];
          return (
            <div className={classes.menuItemIconContainer} style={{transform: 'scale(1.1)', marginLeft: 7}}>
              <div style={{ display: 'flex', alignItems: 'center', marginRight: 2 }}><Icon /></div>
            </div>
          )
        },
      }] : []),
      {
        key: 'divider4',
        itemType: ContextualMenuItemType.Divider,
        itemProps: dividerProps,
      },
      {
        key: 'delete',
        text: 'Delete learning',
        onClick: onCourseDeleteClick,
        itemProps,
        iconProps: {},
        onRenderIcon: () => {
          const Icon = Icons[IconNames.DELETE];
          return (
            <div className={classes.menuItemIconContainer}>
              <Icon
                color={course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running
                   ? theme.palette.neutralTertiary : '#000'}
              />
            </div>
          )
        },
        disabled: course?.status === CourseStatusDto.Published || course?.status === CourseStatusDto.Running,
      },
    ],
  };

  const onRenderLanguageTitle = (options: IDropdownOption[] | undefined): JSX.Element => {
    const Icon = Icons[IconNames.CHANGE_LANGUAGE];
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <Icon />
        <span>Change language</span>
      </div>
    );
  };

  return (
    <div className={classes.options}>

      <div className={classes.dropdownContainer}>
        <MLDropdown
          name='changeLanguage'
          placeholder='Change language'
          options={languages}
          onChange={onLanguageChange}
          selectedKey={lang || course?.documentLanguage}
          dropdownStyle={DropdownStyles.LIGHT}
          onRenderTitle={onRenderLanguageTitle}
          uncontrolled
        />
      </div>

      <div className={classes.contextualMenuContainer}>
        <ContextualMenu
          text='Actions'
          menuProps={contextualMenuProps}
        />
      </div>
    </div>
  )
}

export default Actions;