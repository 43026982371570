import { useContext, useState } from "react";

// import components
import MLForm from "common/ml-components/MLForm";
import MLModal from "common/ml-components/MLModal";
import CourseInfo from "./components/CourseInfo/CourseInfo";
import CourseDetails from "./components/CourseDetails/CourseDetails";

// import redux
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "store/store";
import { generateCourse, getCourses } from "store/slices/courses";

// import react-router
import { useNavigate } from "react-router-dom";

// import contexts
import { LoadingContext } from "contexts/loading-context";

// import helpers
import { arrayBufferToBase64, getFileType, timeout } from "utils/helpers/helpers";
import Document from "./components/Document/Document";
import FileDetails from "./components/FileDetails/FileDetails";
import { GenerateRequestDto, LessonTypeDto } from "api-client";
import { NumberOfLessons } from "common/models/Create/number-of-lessons";

interface IUploadDocumentProps {
  show: boolean;
  onDismiss: () => void;
}

interface IUploadDocumentFormData {
  title: string;
  description: string;
  categoryId: string | undefined;
  numberOfLessons: string | undefined;
  documentLanguage: string | undefined;
  pages: '',
  chapterHeaderPrefix: '',
  typeOfLessons: LessonTypeDto[],
  document: File | undefined;
}

const UploadDocument: React.FC<IUploadDocumentProps> = ({ show, onDismiss }) => {
  const coursesFunctionClientApi = useSelector((state: RootState) => state.clients.clients.coursesFunctionClientApi);
  const { startLoading, stopLoading } = useContext(LoadingContext);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const defaultValues: IUploadDocumentFormData = {
    title: '',
    description: '',
    categoryId: undefined,
    numberOfLessons: undefined,
    documentLanguage: undefined,
    chapterHeaderPrefix: '',
    pages: '',
    typeOfLessons: [],
    document: undefined,
  };

  const toggleShowDetails = () => {
    setShowDetails((prev) => {
      setIsTransitioning(true);
      setTimeout(() => setIsTransitioning(false), 500);
      return !prev
    });
  };

  const onSubmit = async (data: IUploadDocumentFormData) => {
    if (!coursesFunctionClientApi) return;

    try {
      const fileType = data.document ? getFileType(data.document) : null;
      if (!fileType) return;
      const fileData = data.document ? arrayBufferToBase64(await data.document.arrayBuffer()) : null;
      if (!fileData) return;

      const body: GenerateRequestDto = {
        fileConfiguration: {
          fileType,
          chapterHeaderPrefix: data.chapterHeaderPrefix,
          pages: data.pages,
        },
        data: fileData,
        configuration: {
          title: data.title,
          description: data.description,
          categoryId: data.categoryId,
          lessonsNumber: data.numberOfLessons === 'id-1' ? -1 : Number(NumberOfLessons[data.numberOfLessons as keyof typeof NumberOfLessons]),
          documentLanguage: data.documentLanguage,
          hasTextLesson: data.typeOfLessons.includes(LessonTypeDto.Text),
          hasBulletPointsLesson: data.typeOfLessons.includes(LessonTypeDto.BulletPoints),
          hasQuizLesson: data.typeOfLessons.includes(LessonTypeDto.Quiz),
          hasFlashCardLesson: data.typeOfLessons.includes(LessonTypeDto.FlashCard),
          hasAudioOnlyLesson: data.typeOfLessons.includes(LessonTypeDto.AudioOnly),
          hasTrueFalseLesson: data.typeOfLessons.includes(LessonTypeDto.TrueFalse),
          hasCompleteTheSentenceLesson: data.typeOfLessons.includes(LessonTypeDto.CompleteTheSentence),
        },
      };

      await dispatch(generateCourse(body as GenerateRequestDto)).unwrap();
      onDismiss();
      startLoading('Redirecting to the learnings page...');
      await timeout(5000);
      await dispatch(getCourses()).unwrap();
      stopLoading();
      navigate('/learnings');
    } catch (err) {
      console.error('err', err);
    }
  };

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width="50rem"
    >
      <MLForm
        title="Upload a document"
        onSubmit={onSubmit}
        submitButtonLabel="Generate"
        onDismiss={onDismiss}
        defaultValues={defaultValues}
        isTransitioning={isTransitioning}
      >
        <Document />
        <CourseInfo />
        <CourseDetails />
        <FileDetails showDetails={showDetails} toggleShowDetails={toggleShowDetails} />
      </MLForm>
    </MLModal>
  );
};

export default UploadDocument;