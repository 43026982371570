import { useCallback, useState } from 'react';

// import styles
import { getClassNames } from './Learnings.classNames';

// import components
import Header from './components/Header/Header';

// import hooks
import useLearningsPage from './hooks/useLearningsPage';
import { useEffect } from 'react';

// import redux
import { RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { deleteCourseInError, getCourses } from '../../../store/slices/courses';
import DetailsList from './components/DetailsList/DetailsList';
import MLErrorGenerationDialog from 'common/ml-components/MLErrorGenerationDialog';
import useGenerating from './hooks/useGenerating';
import axios from 'axios';
import { IUserProfile } from 'common/models/IUser';
import { CourseV2Dto } from 'api-client';

const Learnings = () => {
  const {
    onMenuClick,
    search,
    updateSearch
  } = useLearningsPage();

  const [courseErrorId, setCourseErrorId] = useState<string>('');
  const dispatch = useAppDispatch();
  const courses = useSelector((state: RootState) => state.courses.courses);
  const graphToken = useSelector((state: RootState) => state.account.graphToken);

  const [authors, setAuthors] = useState<IUserProfile[]>();

  const { courseProgresses } = useGenerating(courses);

  const getAuthors = async (courses: CourseV2Dto[], token: string) => {
    try {
      const uniqueUsernamesSet = new Set<string>();
      courses.forEach(course => {
        if (course.createdBy) {
          uniqueUsernamesSet.add(course.createdBy);
        }
      });
      const uniqueUsernames = Array.from(uniqueUsernamesSet);


      const userDisplayNamesPromises: any = [];
      uniqueUsernames.forEach((username) => {
        userDisplayNamesPromises.push(axios.get(`https://graph.microsoft.com/v1.0/users/${username}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        }));
      });

      const userPhotosPromises: any = [];
        uniqueUsernames.forEach((username) => {
          userPhotosPromises.push(axios.get(`https://graph.microsoft.com/v1.0/users/${username}/photo/$value`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
          responseType: 'blob'
        }));
      });

      const [displayNamesResults, photosResults] = await Promise.allSettled([
        Promise.allSettled(userDisplayNamesPromises),
        Promise.allSettled(userPhotosPromises),
      ]);

      const finalArray: IUserProfile[] = uniqueUsernames.map((username, index) => {
        const displayNameResult =
          displayNamesResults.status === 'fulfilled' &&
          displayNamesResults.value[index]?.status === 'fulfilled'
            ? (displayNamesResults.value[index] as PromiseFulfilledResult<any>).value.data.displayName
            : null;
    
        const photoResult =
          photosResults.status === 'fulfilled' &&
          photosResults.value[index]?.status === 'fulfilled'
            ? URL.createObjectURL((photosResults.value[index] as PromiseFulfilledResult<any>).value.data)
            : null;
    
        return {
          username,
          displayName: displayNameResult,
          photo: photoResult,
        };
      });
    
      // set users
      setAuthors(finalArray);
    } catch (err) {
      console.error(err);
    }
  };

  const init = useCallback(async () => {
    if (!graphToken) return;

    try {
      const courses = await dispatch(getCourses()).unwrap();
      await getAuthors(courses, graphToken);
    } catch (err) {
      console.error(err);
    }
  }, [dispatch, graphToken]);

  useEffect(() => {
    init();
  }, [init]);

  const classes = getClassNames();

  return (
    <section className={classes.learningsPage}>
      <Header onMenuClick={onMenuClick} search={search} updateSearch={updateSearch} />
      <DetailsList
        search={search}
        courses={courses}
        authors={authors}
        onCourseErrorClick={(courseErrorId: string) => setCourseErrorId(courseErrorId)}
        courseProgresses={courseProgresses}
      />
      <MLErrorGenerationDialog
        title='Delete course'
        text='There was an error during the course generation. Would you like to delete it?'
        error={(courseErrorId && courses?.find(el => el.id === courseErrorId)?.errors?.map(el => el.description).join('/n')) || ''}
        show={!!courseErrorId}
        onCancel={() => setCourseErrorId('')}
        onConfirm={async () => {
          try {
            await dispatch(deleteCourseInError({courseIdToDelete: courseErrorId})).unwrap();
            setCourseErrorId('');
          } catch (err) {
            console.error(err);
          }
        }}
      />
    </section>
  )
}

export default Learnings;