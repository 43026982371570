import { InteractionType } from "@azure/msal-browser";
import { useMsal, MsalAuthenticationTemplate, useIsAuthenticated } from "@azure/msal-react";
import { PropsWithChildren, useCallback, useEffect } from "react";
import { authenticationRequest, graphRequest } from "utils/settings/msal";
import { RootState, useAppDispatch } from "../../store/store";
import { setAccount } from "../../store/slices/account";
import Login from "../../pages/Login/Login";
import { useSelector } from "react-redux";

export default function Web({children}: PropsWithChildren) {
  const { token } = useSelector((state: RootState) => state.account);
  const dispatch = useAppDispatch();
  const { accounts, instance } = useMsal();

  const isAuthenticated = useIsAuthenticated();

  const getToken = useCallback(async () => {
    if (!accounts || accounts.length === 0) return;

    try {
      const promises = [
        instance.acquireTokenSilent({
          ...authenticationRequest,
          account: accounts[0],
        }),
        instance.acquireTokenSilent({
          ...graphRequest,
          account: accounts[0],
        }),
      ];
    
      const [apiResultResponse, graphResultResponse] = await Promise.allSettled(promises);
      const result = {
        apiResult: apiResultResponse.status === 'fulfilled' ? apiResultResponse.value : null,
        graphResult: graphResultResponse.status === 'fulfilled' ? graphResultResponse.value : null,
      }

      const accountBody = {
        username: accounts[0].username,
        token: result.apiResult?.accessToken,
        graphToken: result.graphResult?.accessToken,
        roles: (result.apiResult?.idTokenClaims as any).roles as string[],
      }
      dispatch(setAccount(accountBody));

    } catch (err) {
      console.error(err);
      }
  }, [accounts, instance, dispatch]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authenticationRequest}
      loadingComponent={Login}
    >
      {isAuthenticated && token ? children : null}
    </MsalAuthenticationTemplate>
  )
}