import { createSlice } from "@reduxjs/toolkit";

export interface IAccountState {
  username: string | undefined;
  token: string | undefined;
  graphToken: string | undefined;
  roles: string[];
}

const initialState: IAccountState = {
  username: undefined,
  token: undefined,
  graphToken: undefined,
  roles: [],
};

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setAccount: (state,
      action: {
        type: string,
        payload: {
          username: string | undefined,
          token: string | undefined,
          graphToken: string | undefined,
          roles: string[] 
        }
      }) => {
      return {
        ...state,
        username: action.payload.username,
        token: action.payload.token,
        graphToken: action.payload.graphToken,
        roles: action.payload.roles,
      }
    }
  },
});

export default accountSlice.reducer;
export const { 
  setAccount
} = accountSlice.actions;