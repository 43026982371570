import { DetailsList, DetailsListLayoutMode, IColumn, IDetailsRowProps, SelectionMode, buildColumns, keyframes, DetailsRow } from "@fluentui/react";
import { useCallback, useContext, useMemo, useState } from 'react';
import { CourseStatusDto, CourseV2Dto, LightCourseProgressDto } from '../../../../../api-client';
import ListItem from './components/ListItem/ListItem';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../../../store/store';
import { setCourse, setIDLE } from '../../../../../store/slices/courses';
import { getClassNames } from './DetailsList.classNames';
import MLPagination from 'common/ml-components/MLPagination';
import { FiltersContext } from '../../../../../contexts/filters-context';
import { useNavigate } from 'react-router-dom';
import { ApiStatus } from 'common/models/store/api-status';
import Shimmers from './components/Shimmers/Shimmers';
import { ICourse } from "common/models/ICourse";
import { IUserProfile } from "common/models/IUser";

enum ColumnName {
  Title = 'Title',
  Status = 'Status',
  Category = 'Category',
  Created = 'Created',
  Author = 'Author',
}

const fadeIn = keyframes({
  from: {
    opacity: 0,
  }, to: {
    opacity: 1,
  }
});

interface IDetailsListProps {
  search: string;
  courses: CourseV2Dto[] | undefined;
  authors: IUserProfile[] | undefined;
  onCourseErrorClick: (courseErrorId: string) => void;
  courseProgresses: { [key: string]: LightCourseProgressDto } | undefined;
}

const LearningsList: React.FC<IDetailsListProps> = ({ search, courses, authors, onCourseErrorClick, courseProgresses }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filters = useContext(FiltersContext);
  const { username } = useSelector((state: RootState) => state.account);
  const apiStatus = useSelector((state: RootState) => state.courses.apiStatus);
  

  const [page, setPage] = useState<number>(0);
  const [selectedColumn, setSelectedColumn] = useState<string>('Created');
  const [isSortedDescending, setIsSortedDescending] = useState<boolean>(true);

  const onColumnClick = useCallback((ev?: React.MouseEvent<HTMLElement, MouseEvent>, column?: IColumn | undefined) => {
    if (!column) return;
    
    if (selectedColumn === column.key) {
      column.isSortedDescending = !column.isSortedDescending;
      setIsSortedDescending(column.isSortedDescending);
    } else {
      if (column.key === 'Created') {
        column.isSortedDescending = true;
        setIsSortedDescending(true);
      } else {
        column.isSortedDescending = false;
        setIsSortedDescending(false);
      }
    }
    setSelectedColumn(column.key);
  }, [selectedColumn]);

  const shimmers = useMemo(() => ([...new Array(10)].map(() => ({
    [ColumnName.Title]: '',
    [ColumnName.Status]: '',
    [ColumnName.Category]: '',
    [ColumnName.Created]: '',
    [ColumnName.Author]: '',
  }))), []);

  const columns = useMemo(() => {
    const columnNames = [{
      [ColumnName.Title]: '',
      [ColumnName.Status]: '',
      [ColumnName.Category]: '',
      [ColumnName.Created]: '',
      [ColumnName.Author]: '',
    }];
    
    const columns = buildColumns(columnNames, true, onColumnClick, selectedColumn, isSortedDescending);

    columns.forEach(col => {
      switch (col.key) {
        case 'Title':
          col.maxWidth = 400;
          break;

        case 'Status':
          col.maxWidth = 180;
          break;

        case 'Category':
          col.maxWidth = 200;
          break;

        case 'Created':
          col.maxWidth = 200;
          break;
      
        default: return columns;
      }
    });

    return columns.filter(col => col.name !== 'Id');
   }, [isSortedDescending, selectedColumn, onColumnClick]);

  const filteredCourses = useMemo(() => {
    if (!courses) return [];
    
    let filteredCourses: CourseV2Dto[] = [];

    if (filters.learnings.selectedFilter === 0)
      filteredCourses = courses.filter(course => course.title?.toLowerCase().includes(search.toLowerCase())) || [];

    if (filters.learnings.selectedFilter === 1)
      filteredCourses = courses.filter((course): any => course.createdBy === username).filter(course => course.title?.toLowerCase().includes(search.toLowerCase())) || [];
    
    if (filters.learnings.selectedFilter === 2)
      filteredCourses = courses.filter((course): any => course.status === CourseStatusDto.Running).filter(course => course.title?.toLowerCase().includes(search.toLowerCase())) || [];
    
    if (filteredCourses?.length / page < 10) setPage(0);
    return filteredCourses;
  }, [courses, search, filters.learnings.selectedFilter, username, page]);

  const coursesToShow = useMemo(() => {
    if (!filteredCourses) return [];

    return filteredCourses.map((course: CourseV2Dto): any => {
      const getStatus = () => {
        if (!courseProgresses) return course.status;
        if (course.status !== CourseStatusDto.Generating) return course.status;
        if (course.status === CourseStatusDto.Generating) {
          if (courseProgresses[course.id || ''].status !== CourseStatusDto.Generating) return course.status;
          else return courseProgresses[course.id || ''].status;
        }
      }

      const author = authors?.find(author => author.username === course.createdBy);

      return {
        Id: course.id,
        Title: course.title,
        Status: getStatus(),
        Category: course.category?.categoryTranslations?.find(transl => transl.language === 'it-IT')?.value || '',
        Created: course.created,
        Author: author ? author : {  username: course.createdBy, displayName: '', photo: '' },
    }}).sort((a, b) => ((isSortedDescending ? a[selectedColumn] < b[selectedColumn] : a[selectedColumn] > b[selectedColumn]) ? 1 : -1))
      .slice(page * 10, (page * 10) + 10);
  }, [page, selectedColumn, isSortedDescending, filteredCourses, courseProgresses, authors]);

  const numberOfPages = filteredCourses?.length ? Math.ceil(filteredCourses?.length / 10) : 0;

  const onPrevClick = () => setPage(page - 1);
  const onNextClick = () => setPage(page + 1);
  const onIndexClick = (page: number) => setPage(page);

  const onRenderItemColumn = useCallback((item?: any, index?: number | undefined, column?: IColumn | undefined): React.ReactNode => {  
    if (!column || index === undefined) return;

    if (apiStatus.status === ApiStatus.LOADING) {
      return <Shimmers column={column.key} />;
    }

    if (column.key === 'Author' && authors === undefined) {
      return <Shimmers column={column.key} />;
    }

    return (
      <ListItem
        key={item.Id}
        item={item}
        index={index}
        columnKey={column.key}
        lightCourseProgress={{
          status: item.Status,
          latestAction: courseProgresses ? courseProgresses[item.Id]?.latestAction : undefined,
          progressPercentage: courseProgresses ? courseProgresses[item.Id].progressPercentage : courses?.find(course => course.id === item.Id)?.generationProgress
        }}
        authors={authors}
      />
    );
  }, [apiStatus, courseProgresses, courses, authors]);

  const getRows = useCallback((props?: IDetailsRowProps | undefined) => {
    if (!props?.item || !props.onRenderItemColumn) return <></>;

    return (
      <DetailsRow
        key={props.item.Id}
        {...props}
        styles={{ 
          root: {
            cursor: 'pointer',
            animationName: fadeIn,
            animationDuration: '750ms',
          }
        }}
      />
    )
  }, []);

  const onItemClick = useCallback((item: any, index: number | undefined) => {
    if (index === undefined) return;
    if (item.Status === CourseStatusDto.Generating) return;
    // if (item.Status === CourseStatusDto.GenError) return;

    const selectedCourse = courses?.find(course => course.id === item.Id);
    if (!selectedCourse) return;

    if (courses && index !== undefined) {
      if (item.Status !== CourseStatusDto.Generating) {
        const course = courses.find(course => course.title === item.Title);

        // check if error critical
        if (item.Status === CourseStatusDto.GenError) {
          const errors = (course as ICourse).errors;
          if (!errors) return;

          if (errors[errors?.length - 1].level === 'Critical') {
            onCourseErrorClick(course?.id || '');
            return;
          }
        }
        dispatch(setIDLE());
        dispatch(setCourse(selectedCourse.id));
        navigate(`/learnings/${course?.id}`);
      }
    } 
  }, [courses, dispatch, navigate, onCourseErrorClick]);

  const classes = getClassNames();

  return (
    <div className={classes.detailsListContainer}>
      <div
        style={{
          flex: 1,
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <DetailsList
          layoutMode={DetailsListLayoutMode.fixedColumns}
          columns={columns}
          items={
            apiStatus.status === ApiStatus.LOADING ?
            shimmers : 
            coursesToShow
          }
          selectionMode={SelectionMode.none}
          onRenderItemColumn={onRenderItemColumn}
          onRenderRow={getRows}
          onActiveItemChanged={onItemClick}
        />
      </div>
      {coursesToShow.length > 0 &&
        <MLPagination
          totalPages={numberOfPages}
          currentPage={page}
          onPrevClick={onPrevClick}
          onNextClick={onNextClick}
          onIndexClick={onIndexClick}
        />
      }
    </div>
    
  );
};

export default LearningsList;