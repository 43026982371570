// import components
import { getTheme } from "@fluentui/react";
import MLTextField from "common/ml-components/inputs/MLTextField";
import { useFormContext, useWatch } from "react-hook-form";
import { BORDER_RADIUS } from "utils/constants/styles";

interface ITrueFalseLessonProps {}

const TrueFalseLesson: React.FC<ITrueFalseLessonProps> = () => {
  const { setValue } = useFormContext();
  const isTrue = useWatch({ name: 'lesson.isTrue' });

  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        formRequired
      />
      <MLTextField
        name="lesson.sentence"
        label="Sentence"
        multiline
        autoAdjustHeight
        resizable={false}
        formRequired
      />
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10rem', margin: '2rem 0' }}>
        <div
          style={{
            flex: '0 0 10rem',
            height: '5rem',
            borderRadius: BORDER_RADIUS,
            background: isTrue ? getTheme().palette.themePrimary : getTheme().palette.neutralLighter,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            transition: 'background 350ms',
          }}
          onClick={() => {
            setValue('lesson.isTrue', true, { shouldDirty: true });
          }}
        >
          True
        </div>
        <div
          style={{
            flex: '0 0 10rem',
            height: '5rem',
            borderRadius: BORDER_RADIUS,
            background: !isTrue ? getTheme().palette.themePrimary : getTheme().palette.neutralLighter,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            fontSize: '1.5rem',
            fontWeight: 'bold',
            transition: 'background 350ms',
          }}
          onClick={() => {
            setValue('lesson.isTrue', false, { shouldDirty: true });
          }}
        >
          False
        </div>
      </div>
      <MLTextField
        name="lesson.explanation"
        label="Explanation"
        multiline
        autoAdjustHeight
        resizable={false}
      />
    </>
  );
};

export default TrueFalseLesson;
