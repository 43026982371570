import { mergeStyleSets } from "@fluentui/react"

interface ILessonClassNames {
  lessonContainer: string;
  lessonTypeContainer: string;
  lessonContent: string;
  lesson: string
}

export const getClassNames = (): ILessonClassNames => {
  return mergeStyleSets({
    lessonContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      gap: '4rem',
      paddingLeft: '1rem',
    },

    lessonTypeContainer: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      gap: '2rem',
    },

    lessonContent: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
    },

    lesson: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: '2rem',
      overflow: 'scroll',
      paddingRight: '1rem',
      scrollbarGutter: 'auto',
    },
  });
};