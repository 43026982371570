// import components
import { getTheme, IRefObject, ITextField } from "@fluentui/react";
import Edit from "assets/ml-icons/Buttons/Edit/edit";
import Save from "assets/ml-icons/Buttons/Save/Save";
import MLTextField from "common/ml-components/inputs/MLTextField";
import MLCollection from "common/ml-components/MLList/MLCollection/MLCollection";
import MLNewItem from "common/ml-components/MLList/MLNewItem/MLNewItem";
import { createRef, useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BORDER_RADIUS, TEXT_FONT_SIZE } from "utils/constants/styles";

interface ICompleteTheSentenceLessonProps {}

const CompleteTheSentenceLesson: React.FC<ICompleteTheSentenceLessonProps> = () => {
  const { getValues, setValue, watch } = useFormContext();

  const fields = getValues('lesson.options');
  const sentence = getValues('lesson.sentence');
  const [options, setOptions] =
    useState<{ id: string | number; value: string; ref: React.RefObject<HTMLDivElement> }[]>(() => (
      fields.map((field: { id: string | number; value: string; }) => ({
        id: field.id,
        value: field.value,
        ref: createRef<HTMLDivElement>(),
      }))
    ));

  const [isChoosingWord, setIsChoosingWord] = useState(false);
  const choosingSentenceRef = useRef<HTMLDivElement>(null);
  const [sentenceContainerHeight, setSentenceContainerHeight] = useState(0);

  const correctWordId = watch('lesson.correctWordId');

  const onDragEnd = () => {
    setValue('lesson.options', options.map(opt => ({ id: opt.id, value: opt.value })), { shouldDirty: true });
  };

  const onAdd = (value: string) => {
    const maxId = options.length > 0 ? Math.max(...options.map(item => item.id as number)) : -1;
    const newItem = {
      id: maxId + 1,
      value
    };
    setOptions([...options, { ...newItem, ref: createRef<HTMLDivElement>() }]);
    if (newItem.id === 0) {
      setValue('lesson.correctWordId', newItem.id, { shouldDirty: true });
    }
    setValue('lesson.options', [...options, { ...newItem }], { shouldDirty: true });
  };

  const onDelete = (id: string | number) => {
    const newItems = [...options];
    const index = newItems.findIndex((item) => item.id === id);
    newItems.splice(index, 1);
    setOptions(newItems);
    setValue('lesson.options', newItems, { shouldDirty: true });
  };

  const onChange = (id: string | number, newValue: string) => {
    const newItems = [...options];
    const editItem = newItems.find((item) => item.id === id);
    if (!editItem) return;

    editItem.value = newValue;
    setValue('lesson.options', newItems, { shouldDirty: true });
  };

  const handleWordClick = (clickedWord: string, index: number) => {
    // // Match and extract punctuation characters
    // const matchedPunctuation = clickedWord.match(/^[.,!?;:()"']+|[.,!?;:()"']+$/g);
    // const punctuation = matchedPunctuation?.length === 1 ? matchedPunctuation[0] : '';

    // Extract the word without modifying the sentence
    const sanitizedWord = clickedWord.replace(/^[.,!?;:()"']+|[.,!?;:()"']+$/g, '');
  
    // Update sentence to reflect the placeholder change
    const updatedSentence = sentence
      .split(' ')
      .map((word: string, i: number) => {
        // Match and extract punctuation characters
        const matchedPunctuation = word.match(/^[.,!?;:()"']+|[.,!?;:()"']+$/g);
        const punctuation = matchedPunctuation?.length === 1 ? matchedPunctuation[0] : '';
        return (
        i === index
          ? '*****' + punctuation // Replace the clicked word with *****
          : word.includes('*****')
          ? options.find((opt) => opt.id === correctWordId)?.value + punctuation
          : word
        )
      })
      .join(' ');
  
    // Update the options with the sanitized word
    const newItems = [...options];
    const editItem = newItems.find((item) => item.id === correctWordId);
    if (!editItem) return;
  
    editItem.value = sanitizedWord; // Save the sanitized word in options
  
    // Update the state and form values
    setValue('lesson.options', newItems, { shouldDirty: true });
    setValue('lesson.sentence', updatedSentence, { shouldDirty: true });
  };
  

  const getChoosingWordSentence = () => {
    return sentence.split(' ').map((word: string, index: number) => {
      const isPlaceholder = word.includes('*****');
      // Match and extract punctuation characters
      const matchedPunctuation = word.match(/^[.,!?;:()"']+|[.,!?;:()"']+$/g);
      const punctuation = matchedPunctuation?.length === 1 ? matchedPunctuation[0] : '';
      const currentWord = isPlaceholder
        ? options.find((opt) => opt.id === correctWordId)?.value + punctuation
        : word;
  
      return (
        <span
          key={index}
          style={{
            backgroundColor: isPlaceholder ? getTheme().palette.themeLight : 'transparent',
            borderRadius: isPlaceholder ? BORDER_RADIUS : 'none',
            padding: '0.5rem',
            margin: '0 0.2rem',
            cursor: 'pointer',
          }}
          onClick={() => handleWordClick(word, index)}
        >
          {currentWord}
        </span>
      );
    });
  };

  const newItemRef: IRefObject<ITextField> | undefined = createRef<ITextField>();

  const handleChange = (newValue?: string, onChange?: (newValue: string) => void) => {
    if (!newValue || !onChange) return;

    const protectedWord = '*****';
    if (options.length === 0) {
      if (newValue.includes(' ') && newValue.split(' ').length === 2) {
        onAdd(newValue.split(' ')[0]);
        setValue('lesson.correctWordId', 0, { shouldDirty: true });
        onChange(newValue.replace(newValue.split(' ')[0], '*****'));
      } else onChange(newValue);
    }
    else if (newValue.includes(protectedWord)) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (isChoosingWord)
      setSentenceContainerHeight(choosingSentenceRef.current?.clientHeight || 0);
  }, [isChoosingWord]);
  
  return (
    <>
      <MLTextField
        name="lesson.title"
        label="Title"
        multiline
        autoAdjustHeight
        resizable={false}
        formRequired
      />

      <div
        style={{
          overflow: 'hidden',
          width: '100%',
          position: 'relative',
          height: isChoosingWord ? sentenceContainerHeight : 'auto',
        }}
      >
        <MLTextField
          name="lesson.sentence"
          label="Body"
          multiline
          autoAdjustHeight
          resizable={false}
          formRequired
          handleChange={handleChange}
        />
        {isChoosingWord && 
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              padding: '0.5rem',
              backgroundColor: 'white',
              zIndex: 1,
              fontSize: TEXT_FONT_SIZE
            }}
            ref={choosingSentenceRef}
          >
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap', // Ensure words wrap to the next line
              gap: '0rem', // Add spacing between words
              lineHeight: '1', // Adjust for better readability
              margin: '0.5rem 0',
              width: '100%',
            }}
          >
            {getChoosingWordSentence()}
          </div>
        </div>}
      </div>


      <MLCollection
        items={options}
        setItems={setOptions}
        title="Options"
        disabledIndex={options.findIndex((opt: any) => opt.id === correctWordId)}
        alternativeIcon={isChoosingWord ? <Save colored /> : <Edit colored />}
        onAlternativeClick={() => setIsChoosingWord(!isChoosingWord)}
        draggable
        onDragEnd={onDragEnd}
        onDelete={onDelete}
        onChangeValue={onChange}
      />
      <MLNewItem
        componentRef={newItemRef}
        items={options}
        setItems={setOptions}
        onAdd={onAdd}
      />
    </>
  );
};

export default CompleteTheSentenceLesson;
