import { SubmitHandler } from "react-hook-form";
import MLModal from "common/ml-components/MLModal";
import MLTabForm from "common/ml-components/MLTabForm";
import { IMLTab } from "common/ml-components/MLTabForm/MLTabForm";
import React, { createRef, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store/store";
import _ from "lodash";
import Categories from "./components/Categories/Categories";
import { transformCategories } from "utils/helpers/helpers";
import { updateBadges, updateCategories, updateDomains } from "store/slices/settings";
import Domains from "./components/Domains/Domains";
import { v4 } from "uuid";
import Badges from "./components/Badges/Badges";
import { GamificationBadgeDto } from "api-client";

interface ICourseSettingsProps {
  show: boolean;
  onDismiss: () => void;
}

export interface ICourseSettingsFormData {
  categories: {
    collection: { id: string; value: string; }[];
  },
  domains: {
    collection: { id: string; value: string; }[];
  },
  badges: {
    collection: GamificationBadgeDto[];
  },
}

const CourseSettings: React.FC<ICourseSettingsProps> = ({ show, onDismiss }) => {
  const categories = useSelector((state: RootState) => state.settings.categories);
  const scormSettings = useSelector((state: RootState) => state.settings.appSettings.scormSettings);
  const badges = useSelector((state: RootState) => state.settings.badges);
  const featureGamification = useSelector((state: RootState) => state.settings.settings?.planInfo?.featureGamification);
  const featureScormExport = useSelector((state: RootState) => state.settings.settings?.planInfo?.featureScormExport);
  const dispatch = useAppDispatch();

  const filteredCategories: {
    id: string;
    category: string;
  }[] = useMemo(() => {
    const transformedData = categories.filter(item => !item.archived)  // Filter out archived items
      .map(item => {
        const itTranslation = item.categoryTranslations?.find(translation => translation.language === "it-IT");
        return { id: item.id || '', category: itTranslation?.value || '', };
    });

    return transformedData || [];

  }, [categories]);

  const defaultValues: ICourseSettingsFormData = useMemo(() => ({
    categories: {
      collection: filteredCategories.map(cat => ( { id: cat.id, value: cat.category } )) || [],
    },
    domains: {
      collection: scormSettings?.whitelistedDomains?.map(domain => ({ id: v4(), value: domain })) || [],
    },
    badges: {
      collection: badges,
    }
  }), [filteredCategories, scormSettings?.whitelistedDomains, badges]);

  const updateHeight = (key: string, height: number) => {
    setTabs(prevTabs => {
      return prevTabs.map(tab => {
        if (tab.key === key) {
          return {
            ...tab,
            height
          };
        }
        return tab;
      });
    });
  };

  const [tabs, setTabs] =  useState<IMLTab[]>([
    {
      key: 'categories',
      label: 'Categories',
      component: <Categories updateHeight={(height: number) => updateHeight('categories', height)} />,
      ref: createRef<HTMLDivElement>(),
      height: 'auto'
    },
    ...(featureScormExport ? [{
      key: 'domains',
      label: 'SCORM domains',
      component: <Domains updateHeight={(height: number) => updateHeight('domains', height)} />,
      ref: createRef<HTMLDivElement>(),
      height: 'auto' as const
    }] : []),
    ...(featureGamification ? [{
      key: 'badges',
      label: 'Badges',
      component: <Badges updateHeight={(height: number) => updateHeight('badges', height)} />,
      ref: createRef<HTMLDivElement>(),
      height: 'auto' as const
    }] : [])
  ]);

  const compareData = (data: ICourseSettingsFormData) => {
    return (
      !_.isEqual(data.categories.collection, defaultValues.categories.collection) ||
      !_.isEqual(data.domains.collection, defaultValues.domains.collection) ||
      !_.isEqual(data.badges.collection, defaultValues.badges.collection)
    )
  };

  const onSubmit: SubmitHandler<ICourseSettingsFormData> = async (data) => {
    try {
      const promiseArray = [];

      if (!_.isEqual(data.categories.collection, defaultValues.categories.collection)) {
        const updatedCategories = transformCategories(categories, data.categories.collection);
        promiseArray.push(() => dispatch(updateCategories(updatedCategories)).unwrap());
      }

      if (!_.isEqual(data.domains.collection, defaultValues.domains.collection)) {
        promiseArray.push(() => dispatch(updateDomains(data.domains.collection.map(domain => domain.value))).unwrap());
      }

      if (!_.isEqual(data.badges.collection, defaultValues.badges.collection)) {
        promiseArray.push(() => dispatch(updateBadges(data.badges.collection)).unwrap());
      }
     
      await Promise.all(promiseArray.map(dispatchFn => dispatchFn()));
      onDismiss();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <MLModal
      show={show}
      onDismiss={onDismiss}
      width="50rem"
      isTabForm
    >
      <MLTabForm<ICourseSettingsFormData>
        title="Course settings"
        defaultValues={defaultValues}
        tabs={tabs}
        onSubmit={onSubmit}
        onDismiss={onDismiss}
        compareData={compareData}
      />
    </MLModal>
  )
};

export default CourseSettings;